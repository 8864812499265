@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Roboto, system-ui, sans-serif;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-box-shadow: 0px 0px 0px 1000px #ffffff inset !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.disabled-autofill input:-webkit-autofill {
    box-shadow: 0px 0px 0px 1000px #F3F3F4 inset !important;
    -webkit-text-fill-color: #74726F;
}

.disabled-light-grey input:not([type='number']),
.disabled-light-grey .select-input > div,
.disabled-light-grey button,
.disabled-light-grey textarea {
    @apply bg-grey-100 !important;
}

.disabled-light-grey-prefix div {
    @apply bg-grey-100 !important;
}

.select-input > div:first-of-type {
    @apply text-grey-800 border border-grey-200 leading-6 !py-[9px] !px-[8px] rounded-lg focus:outline-blue-500;
}

.select-custom-height > .select-input > div:first-of-type {
    @apply py-[1px]
}

.select-input-error > div:first-of-type {
    @apply border-red-600 border-2;
}

.select-input-highlighted-blue > div:first-of-type {
    @apply border-blue-500 border-2;
}

.select-input-highlighted-red > div:first-of-type {
    @apply border-red-500 border-2;
}

.div-py-0 > div {
    @apply py-0;
}

button:hover svg {
    @apply fill-inherit stroke-inherit
}

.Collapsible__trigger.is-open svg {
    @apply rotate-0
}

.Collapsible{
    @apply min-w-400
}

.contentful-dashboard-page {
    @apply h-3/4 m-4 sm:m-10 p-6 rounded-xl bg-white flex flex-col border border-grey-200 overflow-hidden;
}

.contentful-dashboard-page-lower {
    @apply h-3/4 mx-10 rounded-xl bg-white flex flex-col border border-grey-200 overflow-hidden;
}

.dropdown-overlay {
    @apply fixed w-screen h-screen left-0 top-0 z-10
}

.skeleton-loader {
    background: linear-gradient(90deg, #dddddd 25%, #e8e8e8 40%, #dddddd 60%);
    background-size: 400% 100%;
    animation: skeleton 1.5s ease-in-out infinite;
}

.red-500-svg-path path{
    fill: #FF5963;
}

.blue-800-svg-path path{
    fill: #080E36;
}

.grey-500-svg-path path{
    fill: #74726F;
}

.table-simplified-tr tbody tr:nth-child(odd) {
    @apply bg-grey-100;
}

.table-simplified-th th{
    @apply border-none;
}

.label-grey label{
    @apply mb-5 text-grey-500;
}

.input-center input {
    @apply text-center;
}

 .recharts-cartesian-axis-line, .recharts-cartesian-axis-tick-line{
   @apply hidden;
}

 .one-row-input input{
    @apply w-10/12 xl:w-7/12 h-10;
 }

 .one-row-label label {
     @apply w-2/12 mr-2;
 }

 .w-80-relative > div {
     @apply w-3/4;
 }

 .section-big-title h4 {
     @apply text-xl;
 }

 .section-middle-title h4 {
     @apply text-lg;
 }

 .modal-big-title h4 {
    @apply text-4xl;
 }

 .min-height-input input {
     @apply h-10;
 }

.snippet-title > div:first-child > div{
    @apply flex justify-between items-center w-full;
}

.link-with-icon-reverse svg {
    @apply mr-0 ml-2.5;
}

.grey-300-svg-path path {
    @apply fill-grey-300;
}

.svg-stroke-none {
    stroke: none;
}

.grey-svg-line-stroke line{
    @apply stroke-grey-300;
}
.grey-svg-2-line-stroke:hover line{
    @apply stroke-grey-700;
}
.grey-500-svg-path:hover path {
    @apply fill-grey-700 duration-100;
}

.svg-path-stroke-0 path {
    @apply stroke-0;
}

.green-600-fill-path path {
    @apply fill-green-600;
}

.green-600-path-stroke path {
    @apply stroke-green-600;
}

.white-path-stroke path {
    @apply stroke-white;
}

.dark-blue-svg:hover svg path {
    @apply fill-transparent stroke-blue-800;
}

.checkbox-check-mark:checked:after {
    @apply bg-checkMark;
}

.checkbox-check-mark-all:checked:after{
    @apply bg-minus;
}


.icon-button-with-tooltip-svg svg {
    @apply w-5 h-5 text-red-500 fill-grey-300;
}

.icon-button-with-tooltip-svg-white svg {
    @apply fill-white;
}

.Toastify__toast {
    @apply p-4 rounded-lg;
}

.Toastify__progress-bar{
    @apply !opacity-0;
}

.Toastify__toast-container {
    @apply !max-w-[380px] w-fit;
}

.Toastify__toast-body {
    @apply mr-4;
}

.Toastify__toast-icon {
    @apply mr-4 w-6 h-6;
}

.Toastify__toast-body a {
    @apply pl-1 text-blue-600 font-bold mt-10;
}

.react-tooltip {
    @apply bg-white border border-grey-300 shadow-tooltipShadow px-3 py-5 opacity-100 rounded-2xl !important;
}

.step-progress.react-tooltip {
    @apply py-2 px-2 !important;
}

.error-tooltip-message.react-tooltip {
    @apply text-red-500 !important;
}

.error-tooltip-message::before {
    @apply content-[''] absolute left-0 top-0 bg-red-500 w-full h-full bg-opacity-20;
}

.stepper-tooltip.react-tooltip {
    @apply py-3 !important;
}

.info-tooltip-message.react-tooltip {
    @apply text-violet-500 !important;
}

.info-tooltip-message::before {
    @apply content-[''] absolute left-0 top-0 bg-lightBlue-700 w-full h-full bg-opacity-20;
}

.radio-input {
    @apply text-grey-500 appearance-none ring-1 ring-grey-300 ring-offset-0 rounded-full w-3 h-3 checked:bg-blue-600 p-[3px] cursor-pointer;
}

.radio-input-unset-dimensions {
    @apply text-grey-500 appearance-none ring-1 ring-grey-300 ring-offset-0 rounded-full checked:bg-blue-600 p-[3px];
}

.radio-input-info-highlighted {
    @apply ring-blue-500 ring-2
}

.radio-input-highlighted{
    @apply ring-red-500 ring-2;
}

.input-error-message {
    @apply text-xs pl-[5px] pt-[5px] leading-14;
}

.slider-custom-dots > li {
    @apply border border-lightBlue-800 rounded-[20px] w-9 h-2.5;
}

.slider-custom-dots > li > button {
    @apply w-9 h-2.5;
}

.slider-custom-dots .slick-active {
    @apply bg-blue-600 border-blue-600;
}

.date-picker-input .react-date-picker__wrapper {
    @apply py-3.75 px-4 text-grey-800 leading-6 border border-grey-200 bg-white rounded-lg focus:outline-blue-500 flex items-center;
}

.date-picker-input.react-date-picker--disabled {
    @apply bg-transparent;
}

.date-picker-input.react-date-picker--disabled .react-date-picker__wrapper {
    @apply bg-grey-100 border-grey-200 text-grey-300;
}

.react-calendar {
    @apply !border-grey-300 !rounded-lg !font-roboto !text-grey-800 !overflow-hidden;
}

.react-calendar__month-view__weekdays__weekday {
    @apply !font-inter-tight;
}

.react-calendar__month-view__weekdays__weekday > abbr {
    @apply !no-underline;
}

.react-calendar__month-view__days__day--weekend {
    @apply !text-red-600;
}

.react-calendar__tile:hover {
    @apply !bg-violet-300;
}

.react-calendar__tile--active {
    @apply !bg-violet-500;
}

.react-calendar__tile--now {
    @apply !bg-yellow-300;
}

.inputs-thick-border .date-picker-input .react-date-picker__wrapper {
    @apply border-2;
}

.date-picker-input .react-date-picker__inputGroup {
    @apply leading-6 p-0;
}

.date-picker-input .react-date-picker__button {
    @apply p-0;
}

.date-picker-input .react-date-picker__inputGroup__divider {
    @apply p-0;
}

.date-picker-input .react-calendar__viewContainer button.react-calendar__tile {
    @apply py-4
}

.date-picker-input  .react-date-picker__calendar {
    @apply !inset-auto mt-11;
}

.date-picker-input.react-date-picker {
    @apply static !important;
}

.date-picker-input .react-date-picker__calendar-button svg {
    @apply w-[14px] h-[14px];
}

.date-picker-input.date-picker-input-error .react-date-picker__wrapper {
    @apply border-red-500;
}

.date-picker-input.date-picker-input-highlighted-blue .react-date-picker__wrapper {
    @apply border-blue-500 border-2;
}

.date-picker-input.date-picker-input-highlighted-red .react-date-picker__wrapper {
    @apply border-red-600 border-2;
}

.disabled-grey-select > .select-input > div{
    @apply !bg-grey-100 !text-grey-300;
}

.list-roman-terms-and-condifions .li-list-roman-terms-and-condifions::before {
    content: '(' counter(li, lower-roman) ') ';
}

.li-terms-and-conditions li::before {
    counter-increment: li;
    content: counters(li, ".") ". ";
    margin-right: 30px;
}

.ol-second-level ol ol li {
    padding-left: 15px;
    margin: 0px 0px 34px 0px;
    display: table;
}


.ol-second-level-before ol ol li:before {
    display: table-cell;
    padding-right: 20px;
}

.ol-third-level ol ol ol li {
    padding-left: 0px;
    margin-bottom: 0px;
}


.ol-third-level-before ol ol ol li:before {
    padding-right: 10px;
}

.animated-spin {
    @apply animate-spin [&_.secondary-circle-line]:fill-transparent [&_.secondary-circle-line]:stroke-green-200 [&_.primary-circle-line]:fill-green-700;
}

.whitespace-break-spaces {
    white-space: break-spaces;
}


@keyframes skeleton {
    0% { background-position: 100% 50%; }
    100% { background-position: 0 50%; }
}

@font-face {
    font-family: "ClearSans";
    src: local("ClearSans"), url("./fonts/ClearSans/clear-sans.regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "ClearSans";
    src: local("ClearSans"), url("./fonts/ClearSans/clear-sans.bold.ttf") format("truetype");
    font-weight: bold;
}

.reduced-view {
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
  }

  .fees-frame {
    @apply p-2 rounded-xl bg-white border border-red-200 overflow-hidden text-red-700;
    border-width: 2px;
}